<template>
  <MissionsTable />
</template>

<script>
import MissionsTable from '@/views/ziqni/missions/MissionsTable';

export default {
  name: 'Missions',
  components: {
    MissionsTable,
  },
}
</script>

<style lang="scss">
.achievements {
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
